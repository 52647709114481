import React, { useState } from 'react';
import './Welcome.css';

const Welcome = ({ email, onSend }) => {
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [subjectError, setSubjectError] = useState('');
  const [messageError, setMessageError] = useState('');
  const [wordCount, setWordCount] = useState(0);
  const [captchaToken, setCaptchaToken] = useState(null);

  const handleSend = async (e) => {
    e.preventDefault();

    let valid = true;
    if (!subject) {
      setSubjectError('Please complete all required fields.');
      valid = false;
    } else {
      setSubjectError('');
    }

    if (!message) {
      setMessageError('Please complete all required fields.');
      valid = false;
    } else if (wordCount < 20) {
      setMessageError('Please write at least 20 words before sending.');
      valid = false;
    } else {
      setMessageError('');
    }

    if (valid) {
      try {
        const token = await window.grecaptcha.execute(
          '6Lflow8qAAAAAO-Pliun7NpbQIL2XgNYp6Tl9EpQ',
          { action: 'submit' }
        );
        setCaptchaToken(token);

        onSend({ subject, message, email, token });
        console.log('Form data submitted:', { subject, message });
      } catch (error) {
        console.error('Error with reCAPTCHA:', error);
      }
    }
  };

  const handleMessageChange = (e) => {
    const value = e.target.value;
    setMessage(value);
    setWordCount(value.split(' ').filter((word) => word).length);
  };

  return (
    <div className="container">
      <div className="left-section">
        <div className="logo-container">
          <img src="/logo.png" alt="Logo" className="logo" />
        </div>
        <div className="text-container">
          <div className="sign-in-text">Welcome</div>
          <div className="dropdown-container">
            <div className="dropdown-content">
              <img
                src="/profile-icon.png"
                alt="Profile Icon"
                className="profile-icon"
              />
              <span className="email">{email}</span>
              <svg
                aria-hidden="true"
                className="dropdown-arrow"
                fill="currentColor"
                focusable="false"
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
                xmlns="https://www.w3.org/2000/svg"
              >
                <path d="M7 10l5 5 5-5z"></path>
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div className="right-section">
        <form id="contact-form">
          <input
            type="hidden"
            name="_subject"
            value="New contact form submission"
          />
          <input type="hidden" name="_replyto" value={email} />
          <input type="hidden" name="g-recaptcha-response" value={captchaToken} />
          <div className="input-group">
            <div className={`input-container ${subjectError ? 'error' : ''}`}>
              <input
                type="text"
                name="subject"
                id="subject"
                className="input"
                placeholder=" "
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                required
              />
              <label htmlFor="subject" className="input-label">
                Subject
              </label>
              {subjectError && (
                <div className="error-message subject-error">
                  <svg
                    aria-hidden="true"
                    className="error-icon"
                    fill="currentColor"
                    focusable="false"
                    width="16px"
                    height="16px"
                    viewBox="0 0 24 24"
                    xmlns="https://www.w3.org/2000/svg"
                  >
                    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z"></path>
                  </svg>
                  {subjectError}
                </div>
              )}
            </div>
            <div
              className={`input-container message ${
                messageError ? 'error' : ''
              }`}
            >
              <textarea
                name="message"
                id="message"
                className="input"
                placeholder=" "
                value={message}
                onChange={handleMessageChange}
                required
              />
              <label htmlFor="message" className="input-label">
                Message
              </label>
              {messageError && (
                <div className="error-message message-error">
                  <svg
                    aria-hidden="true"
                    className="error-icon"
                    fill="currentColor"
                    focusable="false"
                    width="16px"
                    height="16px"
                    viewBox="0 0 24 24"
                    xmlns="https://www.w3.org/2000/svg"
                  >
                    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z"></path>
                  </svg>
                  {messageError}
                </div>
              )}
              <div className="word-count">{wordCount} words</div>
            </div>
          </div>
          <button className="next-button" onClick={handleSend}>
            Send
          </button>
        </form>
      </div>
    </div>
  );
};

export default Welcome;
